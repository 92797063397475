// import mock from '@/mocks'
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import user from './user.module'
import filters from './filters.module'
import estate from './estate.module'
import agency from './agencies.module'
import client from './clients.module'
import complexes from './complexes.module'
import logs from './logs.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: localStorage.lang ? `/${localStorage.lang}/api/v1` : `/${process.env.VUE_APP_I18N_FALLBACK_LOCALE}/api/v1`,
    notification: {
      visible: false,
      type: '',
      content: ''
    }
  },
  getters: {},
  mutations: {
    setNotification (state, payload) {
      state.notification = payload
    },
    setApi (state, payload) {
      state.api = `${payload}/api/v1`
    }
  },
  actions: {
    async addPhone ({ state, commit }, payload) {
      try {
        const { data } = await axios.post(`${state.api}/taxonomy_term/phones`, payload, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json'
          }
        })
        return {
          name: data.data?.attributes?.name,
          field_phone: data.data?.attributes?.field_phone,
          tid: data.data?.attributes?.drupal_internal__tid,
          uuid: data.data?.id
        }
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message })
      }
    },
    async deletePhone ({ state, commit }, payload) {
      try {
        const res = await axios.delete(`/${payload.lang}/api/v1/taxonomy_term/phones/${payload.id}`, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json'
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText })
        commit('complexes/deletePhoneNumber', payload.id)
        commit('agency/deletePhoneNumber', payload.id)
        return res.status
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message })
      }
    },
    async editPhone ({ state, commit }, payload) {
      const phoneId = payload.data.id
      try {
        const res = await axios.patch(`${state.api}/taxonomy_term/phones/${phoneId}`, payload, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json'
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText })
        return res.status
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message })
      }
    },
    async tg ({ state, commit }, message) {
      try {
        const { data } = await axios.get(`${state.api}/telegram`, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json'
          }
        })
        const botKey = data[0]?.bot_key
        const chatID = data[0]?.chat_id
        await axios.get(`https://api.telegram.org/bot${botKey}/sendMessage?chat_id=${chatID}&text=${message}`)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message })
      }
    }
  },
  modules: { user, filters, estate, agency, client, complexes, logs }
})
