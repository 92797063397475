export const COUNTRIES = {
  TURKEY: '108429'
}

export const LOG_TYPES = {
  ALL: 'all',
  USER: 'user',
  NODE: 'node',
  CONTENT: 'content'
}
