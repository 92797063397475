import axios from 'axios'
export default {
  namespaced: true,
  state: {
    items: [],
    pager: {},
    clientToAdd: {},
    clientToEdit: {}
  },
  getters: {
    api (state, getters, rootState, rootGetters) {
      return rootState.api
    }
  },
  mutations: {
    setClients (state, payload) {
      state.items = payload
    },
    setPager (state, payload) {
      state.pager = payload
    },
    setClientToAdd (state, payload) {
      state.clientToAdd = payload
    }
  },
  actions: {
    async getClients ({ commit, getters }, options) {
      const params = {}
      params.page = options?.page || 0
      try {
        const { data } = await axios.get(`${getters.api}/clients`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          },
          params
        })
        commit('setClients', data.rows)
        commit('setPager', data.pager)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async getCurrentClients ({ commit, getters }, options) {
      const params = {}
      params.page = options?.page || 0
      try {
        const { data } = await axios.get(`${getters.api}/clients-current-user`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          },
          params
        })
        commit('setClients', data.rows)
        commit('setPager', data.pager)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async createClient ({ commit, dispatch, getters }, { edit = false, payload }) {
      try {
        const res = await axios({
          url: `${getters.api}/node/client${edit ? `/${payload.data.id}` : ''}`,
          method: edit ? 'patch' : 'post',
          data: payload,
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': localStorage.csrf_token
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        dispatch('getCurrentClients')
        return res.status
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async deleteClient ({ commit, dispatch, getters }, id) {
      try {
        const res = await axios.delete(`${getters.api}/node/client/${id}`, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': localStorage.csrf_token
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        dispatch('getCurrentClients')
        return res.status
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    }
  }
}
