import axios from 'axios'
import { LOG_TYPES } from '@/consts'

export default {
  namespaced: true,
  state: () => ({
    logsList: [],
    pager: {}
  }),
  getters: {
    api (state, getters, rootState, rootGetters) {
      return rootState.api
    }
  },
  mutations: {
    setLogsList (state, payload) {
      state.logsList = payload
    },
    setPager (state, payload) {
      state.pager = payload
    }
  },
  actions: {
    async getLogs ({ commit, getters }, options) {
      const params = {}
      params.page = options?.page || 0
      if (options?.type !== LOG_TYPES.ALL) params.type = options.type
      if (options?.uuid) params.uuid = options?.uuid
      try {
        const { data } = await axios.get(`${getters.api}/${(params?.type || params?.uuid) ? 'log' : 'log-all'}`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          },
          params
        })
        commit('setLogsList', data.rows)
        commit('setPager', data.pager)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    }
  }
}
