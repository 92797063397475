<template>
  <v-app :key="$i18n.locale">
    <v-app-bar dark absolute flat :style="{ background: $route.name === 'estate-view' ? '#0e0e0e!important' : '',}">
      <v-container>
        <v-row>
          <v-col>
            <div class="d-flex align-center justify-space-between">
              <a
                href="/"
                class="text-h6 font-weight-bolder text-decoration-none white--text"
                v-html="$t('app.name')"
              />
              <app-menu v-if="!isMobileOrIPad && isAuth()" />
              <v-app-bar-nav-icon
                v-if="isMobileOrIPad && isAuth()"
                @click.stop="drawer = !drawer"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary dark class="py-6">
      <app-menu vertical />
    </v-navigation-drawer>
    <v-main
      class="pb-0 pt-0"
      :style="{
        background:
          $route.name === 'estate-view'
            ? 'white'
            : `url(${require('./assets/images/bg.png')}) center center/cover no-repeat fixed`,
      }"
    >
      <div :style="{'padding-top': '146px', 'padding-bottom': '200px'}">
        <notification-banner />
        <router-view/>
      </div>
    </v-main>
    <v-footer absolute height="94px" class="px-4 py-2">
      <v-container>
        <v-row>
          <v-col class="d-flex">
            <v-btn icon color="white">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon color="white">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-spacer />
            <locale-switcher />
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import permissions from '@/mixins/permissions'
import viewport from '@/mixins/viewport'

export default {
  name: 'App',
  components: {
    appMenu: () => import('@/components/AppMenu.vue'),
    'locale-switcher': () => import('./components/LocaleSwitcher.vue'),
    'notification-banner': () => import('./components/NotificationBanner.vue')
  },
  mixins: [permissions, viewport],
  data: () => ({ drawer: false, selectedItem: 0 }),
  methods: {
    ...mapActions('user', ['getUserRoles'])
  },
  mounted () {
    this.getUserRoles()
  }
}
</script>
