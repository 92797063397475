import axios from 'axios'
import { COUNTRIES } from '@/consts'
export default {
  namespaced: true,
  state: {
    items: [],
    filters: {
      cities: [],
      districts: [],
      type: [],
      rooms: [],
      source: [],
      act: [],
      building_types: [],
      conditions: [],
      materials: [],
      labels: [],
      company_types: [],
      infrastructure: []
      // price_range: [],
      // floorage_range: [],
      // floors_range: []
    },
    currentFilters: {
      country_id: COUNTRIES.TURKEY,
      archived: 0,
      city: '',
      cities: [],
      districts: [],
      act: [],
      source: [],
      type: [],
      rooms: [],
      building_type: [],
      conditions: [],
      materials: [],
      labels: [],
      price_range: [],
      floorage_range: [],
      floors_range: [],
      company_types: [],
      nid_value: null
    },
    pager: {},
    estateToView: {},
    estateToAdd: {}
  },
  getters: {
    showArchived (state) {
      return state.currentFilters.archived
    },
    api (state, getters, rootState, rootGetters) {
      return rootState.api
    },
    defaultCountryID (state) {
      return state.currentFilters.country_id
    }
  },
  mutations: {
    setFilters (state, { filters, taxonomy, data }) {
      if (taxonomy) {
        state[filters][taxonomy] = data
      } else {
        state[filters] = data
      }
    },
    resetFilters (state) {
      state.currentFilters.country_id = COUNTRIES.TURKEY
      state.currentFilters.city = ''
      state.currentFilters.act = []
      state.currentFilters.source = []
      state.currentFilters.type = []
      state.currentFilters.rooms = []
      state.currentFilters.building_type = []
      state.currentFilters.conditions = []
      state.currentFilters.materials = []
      state.currentFilters.labels = []
      state.currentFilters.price_range = []
      state.currentFilters.floorage_range = []
      state.currentFilters.floors_range = []
      state.currentFilters.nid_value = null
      state.filters.districts = []
    },
    setEstates (state, payload) {
      state.items = payload
    },
    setPager (state, payload) {
      state.pager = payload
    },
    setEstateToView (state, payload = {}) {
      state.estateToView = payload
    },
    setEstateToAdd (state, payload) {
      state.estateToAdd = payload
    }
    // updateEstateImages (state, { index, data }) {
    //   state.items[index].field_photos = data.map(photo => photo.uri).join(';')
    // }
  },
  actions: {
    async getFilters ({ commit, getters }, { params, taxonomy }) {
      try {
        const { data, status } = await axios.get(`${getters.api}/taxonomy`, {
          params,
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`
          }
        })
        commit('setFilters', { filters: 'filters', taxonomy, data: data.rows })
        return status
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async getEstates ({ state, commit, getters }, options) {
      try {
        const params = {}
        params.page = options?.page || 0
        params.archived = state.currentFilters.archived
        if (state.currentFilters.act) { params.busines_type_id = state.currentFilters.act }
        if (state.currentFilters.rooms) { params.number_of_rooms_id = state.currentFilters.rooms }
        if (state.currentFilters.source) { params.source_id = state.currentFilters.source }
        if (state.currentFilters.type) { params.type_id = state.currentFilters.type }
        if (state.currentFilters.building_type) { params.type_building_id = state.currentFilters.building_type }
        if (state.currentFilters.conditions) { params.condition_id = state.currentFilters.conditions }
        if (state.currentFilters.materials) { params.material_id = state.currentFilters.materials }
        if (state.currentFilters.labels) { params.labels_id = state.currentFilters.labels }
        if (state.currentFilters.nid_value) { params.nid_value = state.currentFilters.nid_value }
        params.sort_by = 'created'
        const { data } = await axios.get(
          options?.onlyMine
            ? `${getters.api}/estates-by-current-user`
            : `${getters.api}/estates/${state.currentFilters?.city || 'all'}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.access_token}`,
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json'
            },
            params
          })
        commit('setEstates', data.rows)
        commit('setPager', data.pager)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async getEstate ({ commit, getters }, id) {
      try {
        const { data } = await axios.get(`${getters.api}/estate-by-uuid/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          }
        })
        commit('setEstateToView', data[0])
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async addEstate ({ state, commit, dispatch, getters }, { edit = false, payload }) {
      try {
        const res = await axios({
          url: `${getters.api}/node/estate${edit ? `/${payload.data.id}` : ''}`,
          method: edit ? 'patch' : 'post',
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          },
          data: payload
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        await dispatch('getEstate', res.data.data.id)
        if (!edit) dispatch('getEstates')
        const tgMessage = `ESTATE ${edit ? 'UPDATED' : 'CREATED'}%0A${state.estateToView?.title}%0A${state.estateToView?.field_region}%0A${state.estateToView?.field_type}, ${state.estateToView?.field_price}, ${state.estateToView?.field_rooms}%0A${state.estateToView?.owner[0]?.field_first_name} ${state.estateToView?.owner[0]?.field_last_name}%0A${state.estateToView?.owner[0]?.field_phone}%0A`
        dispatch('tg', tgMessage, { root: true })
        return res.data.data.id
      } catch (error) {
        if (error.response?.data?.errors?.length > 0) {
          const errors = error.response?.data?.errors?.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async deleteEstate ({ state, commit, dispatch, getters }, id) {
      try {
        await dispatch('getEstate', id)
        await axios.delete(`${getters.api}/node/estate/${id}`, {
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: 'Deleted' }, { root: true })
        const tgMessage = `ESTATE DELETED%0A${state.estateToView?.title}%0A${state.estateToView?.field_region}%0A${state.estateToView?.field_type}, ${state.estateToView?.field_price}, ${state.estateToView?.field_rooms}%0A${state.estateToView?.owner[0]?.field_first_name} ${state.estateToView?.owner[0]?.field_last_name}%0A${state.estateToView?.owner[0]?.field_phone}%0A`
        dispatch('tg', tgMessage, { root: true })
        dispatch('getEstates')
      } catch (error) {
        if (error.response.data.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async uploadEstateImage ({ commit, dispatch, getters }, { id, file }) {
      try {
        const res = await axios.post(`${getters.api}/node/estate/${id}/field_photos`, file, {
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `file; filename="${file.name}"`
          }
        })
        if (res.status === 200 || res.status === 201) {
          dispatch('getEstate', id)
        }
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    }
  }
}
