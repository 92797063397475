import axios from 'axios'
import router from '../router'
export default {
  namespaced: true,
  state: () => ({
    usersList: [],
    usersShort: [],
    pager: {},
    info: {},
    rolesList: [],
    userToAdd: {}
  }),
  getters: {
    agencyId (state) {
      return state.info.member_of[0]?.uuid || state.info.owner_of[0]?.uuid || null
    },
    roles (state) {
      return state.rolesList.map(r => ({
        name: r.attributes?.label?.toLowerCase(),
        value: r.attributes?.drupal_internal__id,
        id: r.id
      }))
    },
    api (state, getters, rootState, rootGetters) {
      return rootState.api
    }
  },
  mutations: {
    setUsers (state, payload) { state.usersList = payload },
    setUsersShort (state, payload) { state.usersShort = payload },
    setUser (state, payload) { state.info = payload },
    setRoles (state, payload) { state.rolesList = payload },
    setPager (state, payload) { state.pager = payload },
    setUserToAdd (state, payload) { state.userToAdd = payload }
  },
  actions: {
    async login ({ state, commit, dispatch }, payload) {
      try {
        const { data } = await axios.post('api/v1/user/login', payload, {
          headers: { 'Content-Type': 'application/json' },
          params: { _format: 'json' }
        })
        localStorage.setItem('access_token', data.access_token)
        localStorage.setItem('logout_token', data.logout_token)
        localStorage.setItem('csrf_token', data.csrf_token)
        localStorage.setItem('user', data.current_user.name)
        await dispatch('getUserInfo')
        localStorage.setItem('roles', state.info.roles_target_id || '')
        router.replace('/dashboard')
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    logout ({ commit }) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('logout_token')
      localStorage.removeItem('csrf_token')
      localStorage.removeItem('user')
      localStorage.removeItem('roles')
      router.replace('/login')
      commit('setNotification', { visible: true, type: 'success', content: 'You\'ve been successfully logged out' }, { root: true })
    },
    async getUserInfo ({ commit, getters }) {
      try {
        const { data } = await axios.get(`${getters.api}/user-current`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          }
        })
        commit('setUser', data[0])
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async getUsers ({ getters, commit }, options) {
      const params = {}
      params.page = options?.page || 0
      try {
        const { data } = await axios.get(`${getters.api}/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          },
          params
        })
        commit('setUsers', data.rows)
        commit('setPager', data.pager)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async getUserByID ({ getters, commit }, id) {
      try {
        const { data } = await axios.get(`${getters.api}/user-by-uuid/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          }
        })
        return data
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async getUserRoles ({ commit, getters }) {
      try {
        const { data } = await axios.get(`${getters.api}/user_role/user_role`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`
          }
        })
        commit('setRoles', data.data)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async createUser ({ commit, getters, dispatch }, payload) {
      try {
        const res = await axios({
          method: payload.data.id ? 'patch' : 'post',
          url: `${getters.api}/user/user${payload.data.id ? `/${payload.data.id}` : ''}`,
          data: payload,
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': localStorage.csrf_token
          }
        })
        const tgMessage = `USER ${payload.data.id ? 'UPDATED' : 'CREATED'}%0A${payload?.data?.attributes?.field_first_name} ${payload?.data?.attributes?.field_last_name} (${payload?.data?.attributes?.name})%0A${payload?.data?.attributes?.mail}%0A${payload?.data?.attributes?.field_phone}`
        dispatch('tg', tgMessage, { root: true })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        dispatch('getUsers')
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async updateUser ({ commit, dispatch, getters }, payload) {
      try {
        const res = await axios.patch(`${getters.api}/user/user/${payload.data.id}`, payload, {
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          }
        })
        if (res.status === 200) {
          commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
          const tgMessage = `USER UPDATED%0A${payload?.data?.attributes?.field_first_name} ${payload?.data?.attributes?.field_last_name} (${payload?.data?.attributes?.name})%0A${payload?.data?.attributes?.mail}%0A${payload?.data?.attributes?.field_phone}`
          dispatch('tg', tgMessage, { root: true })
          dispatch('getUserInfo')
        }
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async updateUserAvatar ({ commit, dispatch, getters }, { id, file }) {
      try {
        const res = await axios.post(`${getters.api}/user/user/${id}/user_picture`, file, {
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': 'file; filename="avatar.png"'
          }
        })
        if (res.status === 200) {
          commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
          dispatch('getUserInfo')
        }
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async deleteUser ({ commit, dispatch, getters }, id) {
      try {
        const userData = await dispatch('getUserByID', id)
        const res = await axios.delete(`${getters.api}/user/user/${id}`, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': localStorage.csrf_token
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        console.log(userData)
        const tgMessage = `USER DELETED%0A${userData[0]?.field_first_name} ${userData[0]?.field_last_name} (${userData[0]?.name})%0A${userData[0]?.mail}%0A${userData[0]?.field_phone}`
        dispatch('tg', tgMessage, { root: true })
        dispatch('getUsers')
        return res.status
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async getUsersShort ({ commit, dispatch, getters }) {
      try {
        const { data } = await axios.get(`${getters.api}/users-short`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          },
          params: { status: 1 }
        })
        commit('setUsersShort', data.rows)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    }
  }
}
