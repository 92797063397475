import axios from 'axios'
export default {
  namespaced: true,
  state: {
    items: [],
    filters: {},
    currentFilters: [],
    pager: {},
    complexToView: {},
    complexToAdd: {},
    complexPhones: []
  },
  getters: {
    getComplexPhones (state, getters, rootState, rootGetters) {
      return state.complexPhones
    },
    api (state, getters, rootState, rootGetters) {
      return rootState.api
    },
    getComplexesNames (state) {
      const complexesNamesList = []
      state.items.forEach((complex) => complexesNamesList.push({
        title: complex.title,
        uuid: complex.uuid
      }))
      return complexesNamesList
    }
  },
  actions: {
    async getComplexes ({ state, commit, getters }, options) {
      const params = {}
      params.page = options?.page || 0
      params.sort_by = 'created'
      try {
        const { data } = await axios.get(`${getters.api}/complexes`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.access_token}`,
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json'
            },
            params
          })
        commit('setComplexes', data.rows)
        commit('setPager', data.pager)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async getComplex ({ commit, getters }, id) {
      try {
        const { data } = await axios.get(`${getters.api}/complex/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          }
        })
        commit('setComplexToView', data[0])
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async getComplexesByFilters ({ commit, getters }, { params }) {
      try {
        const { data, status } = await axios.get(`${getters.api}/complexes`, {
          params,
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`
          }
        })
        commit('setFilters', { filters: 'filters', data: data.rows })
        commit('setComplexes', data.rows)
        return status
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async addComplex ({ state, commit, dispatch, getters }, { edit = false, payload }) {
      try {
        const res = await axios({
          url: `${getters.api}/node/complex${edit ? `/${payload.data.id}` : ''}`,
          method: edit ? 'patch' : 'post',
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          },
          data: payload
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        await dispatch('getComplex', res.data.data.id)
        if (!edit) dispatch('getComplexes', { onlyMine: true })
        const tgMessage = `COMPLEX ${edit ? 'UPDATED' : 'CREATED'}%0A${state.complexToView?.title}%0A${state.complexToView?.field_complex_region}%0A${state.complexToView?.field_complex_realestate_type}, ${state.complexToView?.field_complex_delivery_date}%0AOwner ID: ${state.complexToView?.owner_uuid}`
        dispatch('tg', tgMessage, { root: true })
        return res.data.data.id
      } catch (error) {
        if (error.response?.data?.errors?.length > 0) {
          const errors = error.response?.data?.errors?.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async uploadComplexImage ({ commit, dispatch, getters }, { id, file }) {
      try {
        const res = await axios.post(`${getters.api}/node/complex/${id}/field_complex_photos`, file, {
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `file; filename="${file.name}"`
          }
        })
        if (res.status === 200 || res.status === 201) {
          dispatch('getComplex', id)
        }
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async deleteComplex ({ state, commit, dispatch, getters }, id) {
      try {
        await axios.delete(`${getters.api}/node/complex/${id}`, {
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: 'Deleted' }, { root: true })
        const tgMessage = `COMPLEX DELETED%0A${state.complexToView?.title}%0A${state.complexToView?.field_complex_region}%0A${state.complexToView?.field_complex_realestate_type}, ${state.complexToView?.field_complex_delivery_date}%0AOwner ID: ${state.complexToView?.owner_uuid}`
        dispatch('tg', tgMessage, { root: true })
        dispatch('getComplexes')
      } catch (error) {
        if (error.response.data.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    }
  },
  mutations: {
    updateComplexPhones (state, payload) {
      state.complexPhones = payload
    },
    editPhoneNumber (state, payload) {
      state.complexPhones[payload.phoneToEdit].field_phone = payload.newValue.phone
      state.complexPhones[payload.phoneToEdit].name = payload.newValue.name
    },
    deletePhoneNumber (state, payload) {
      state.complexPhones = state.complexPhones.filter(complexPhone => complexPhone.uuid !== payload)
    },
    setComplexes (state, payload) {
      state.items = payload
    },
    setPager (state, payload) {
      state.pager = payload
    },
    setComplexToView (state, payload = {}) {
      state.complexToView = payload
    },
    setComplexToAdd (state, payload) {
      state.complexToAdd = payload
    },
    setFilters (state, { filters, data }) {
      state[filters] = data
    },
    resetFilters (state) {
      state.currentFilters = []
    }
  }
}
