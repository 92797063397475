<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SiteDashboard',
  computed: {
    ...mapGetters('estate', ['defaultCountryID'])
  },
  methods: {
    ...mapActions('estate', ['getFilters']),
    ...mapActions('agency', ['getAgencies']),
    ...mapActions('user', ['getUserInfo'])
  },
  mounted () {
    this.getFilters({
      params: { vid: 'country', parent_id: this.defaultCountryID },
      taxonomy: 'cities'
    })
    this.getFilters({
      params: { vid: 'business_type' },
      taxonomy: 'act'
    })
    this.getFilters({
      params: { vid: 'number_of_rooms' },
      taxonomy: 'rooms'
    })
    this.getFilters({
      params: { vid: 'source' },
      taxonomy: 'source'
    })
    this.getFilters({
      params: { vid: 'type' },
      taxonomy: 'type'
    })
    this.getFilters({
      params: { vid: 'infrastructure' },
      taxonomy: 'infrastructure'
    })
    this.getFilters({
      params: { vid: 'type_building' },
      taxonomy: 'building_type'
    })
    this.getFilters({
      params: { vid: 'condition' },
      taxonomy: 'conditions'
    })
    this.getFilters({
      params: { vid: 'material' },
      taxonomy: 'materials'
    })
    this.getFilters({
      params: { vid: 'labels' },
      taxonomy: 'labels'
    })
    this.getFilters({
      params: { vid: 'company_type' },
      taxonomy: 'company_types'
    })
    this.getAgencies()
    this.getUserInfo()
  }
}
</script>
