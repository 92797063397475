export default {
  computed: {
    isMobile () { return this.$vuetify.breakpoint.xs },
    isIPad () { return this.$vuetify.breakpoint.sm },
    isLaptop () { return this.$vuetify.breakpoint.md },
    isLargeLaptop () { return this.$vuetify.breakpoint.lg },
    isDesktop () { return this.$vuetify.breakpoint.xl },
    isMobileOrIPad () { return this.isMobile || this.isIPad }
  }
}
