import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Dashboard from '@/views/Dashboard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    component: Dashboard,
    redirect: { name: 'estates' },
    children: [
      {
        path: 'estates',
        name: 'estates',
        component: () => import(/* webpackChunkName: "estates" */ '@/views/dashboard/Estates')
      },
      {
        path: 'my-estates',
        name: 'my-estates',
        component: () => import(/* webpackChunkName: "my-estates" */ '@/views/dashboard/MyEstates')
      },
      {
        path: 'my-clients',
        name: 'my-clients',
        component: () => import(/* webpackChunkName: "my-clients" */ '@/views/dashboard/Clients')
      },
      {
        path: 'agencies',
        name: 'agencies',
        component: () => import(/* webpackChunkName: "agencies" */ '@/views/dashboard/Agencies')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/dashboard/Users')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/dashboard/Profile')
      },
      {
        path: 'estate/:id',
        name: 'estate-view',
        component: () => import(/* webpackChunkName: "estate-view" */ '@/views/EstateView')
      },
      {
        path: 'complexes',
        name: 'complexes',
        component: () => import('@/views/dashboard/Complexes')
      },
      {
        path: 'complex/:id',
        name: 'complex-view',
        component: () => import('@/views/ComplexView')
      },
      {
        path: 'logs',
        name: 'logs',
        component: () => import(/* webpackChunkName: "users" */ '@/views/dashboard/Logs.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/SiteLogin')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !localStorage.user) next({ name: 'login' })
  else if (to.name === 'login' && localStorage.user) next({ path: '/dashboard' })
  else {
    if (store.state.notification?.visible) {
      store.commit('setNotification', { visible: false, type: '', content: '' })
    }
    next()
  }
})

export default router
