import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)
const vuetifyOptions = {
  theme: {
    themes: {
      light: {
        primary: '#AB8D69',
        success: '#A58867',
        secondary: '#C09E76',
        grey: '#303030'
      }
    },
    options: {
      customProperties: true
    }
  }
}
export default new Vuetify(vuetifyOptions)
