export default {
  namespaced: true,
  state: () => ({
    list: {
      action: ['sell', 'rent'],
      material_type: ['brick', 'panels', 'blocks', 'monolith', 'foamconcrete', 'aeratedconcrete', 'slag', 'shellrock', 'wood', 'metal'],
      estate_condition: ['no_decoration', 'no_internal_works', 'no_finishing_works', 'soviet_decoration', 'redecoration', 'partial_decoration', 'overhaul', 'euro_decoration', 'author_design'],
      estate_type: ['apartment', 'house', 'commerce'],
      estate_source: ['rieltor', 'owner'],
      user_label: ['liquid', 'non_liquid', 'owner', 'urgent_sell', 'alternate', 'actual', 'non_actual', 'rieltor', 'recall', 'exclusive']
    },
    currentFilters: {
      action: 'sell',
      material_type: [],
      estate_condition: [],
      estate_type: 'apartment',
      estate_source: ['owner'],
      user_label: []
    }
  })
}
