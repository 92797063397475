'use strict'

import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
// Full config:  https://github.com/axios/axios#request-config

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL || ''
// axios.defaults.headers.common.Authorization = `Bearer ${localStorage.access_token}`
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.VUE_APP_API_URL || ''
  timeout: 60 * 1000, // Timeout
  withCredentials: true // Check cross-site Access-Control
}

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    if (error.response.status === 403) {
      localStorage.removeItem('user')
      localStorage.removeItem('roles')
      router.replace('/')
    }
    // Do something with response error
    return Promise.reject(error)
  }
)

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    alert(error)
    // Do something with response error
    return Promise.reject(error)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
