import axios from 'axios'
export default {
  namespaced: true,
  state: {
    items: [],
    // pager: {},
    agencyToEdit: {},
    agencyPhones: []
  },
  getters: {
    getAgencyPhones (state, getters, rootState, rootGetters) {
      return state.agencyPhones
    },
    api (state, getters, rootState, rootGetters) {
      return rootState.api
    }
  },
  mutations: {
    updateAgencyPhones (state, payload) {
      state.agencyPhones = payload
    },
    editPhoneNumber (state, payload) {
      state.agencyPhones[payload.phoneToEdit].field_phone = payload.newValue.phone
      state.agencyPhones[payload.phoneToEdit].name = payload.newValue.name
    },
    deletePhoneNumber (state, payload) {
      state.agencyPhones = state.agencyPhones.filter(agencyPhone => agencyPhone.uuid !== payload)
    },
    setAgencies (state, payload) {
      state.items = payload
    },
    setAgency (state, { index, data }) {
      Object.assign(state.items[index], data)
    },
    setPager (state, payload) {
      state.pager = payload
    },
    setAgencyToEdit (state, payload) {
      state.agencyToEdit = payload
    }
  },
  actions: {
    async getAgencies ({ commit, getters }, options) {
      const params = {}
      params.page = options?.page || 0
      try {
        const { data } = await axios.get(`${getters.api}/agencies`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          },
          params
        })
        commit('setAgencies', data)
        // commit('setPager', data.pager)
      } catch (error) {
        commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
      }
    },
    async getAgency ({ state, commit, getters }, id) {
      try {
        const res = await axios.get(`${getters.api}/agency/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json'
          }
        })
        const index = await state.items.findIndex(agency => agency.uuid === id)
        commit('setAgency', { index, data: res.data[0] })
        return res.data[0]
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async createAgency ({ state, commit, getters, dispatch }, payload) {
      try {
        const res = await axios.post(`${getters.api}/node/agency`, payload, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': localStorage.csrf_token
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        const tgMessage = `AGENCY CREATED%0A${payload.data.attributes.title}%0A${payload.data.attributes.field_agency_address}%0A${payload.data.attributes.field_email}`
        dispatch('tg', tgMessage, { root: true })
        dispatch('getAgencies')
        return res.status
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async editAgency ({ getters, commit, dispatch }, { id, payload }) {
      try {
        const res = await axios.patch(`${getters.api}/node/agency/${id}`, payload, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': localStorage.csrf_token
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        const tgMessage = `AGENCY UPDATED%0A${payload.data.attributes.title}%0A${payload.data.attributes.field_agency_address}%0A${payload.data.attributes.field_email}`
        dispatch('tg', tgMessage, { root: true })
        dispatch('getAgencies')
        return res.data.data.id
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async deleteAgency ({ commit, dispatch }, id) {
      try {
        const agencyToDelete = await dispatch('getAgency', id)
        const res = await axios.delete(`api/v1/node/agency/${id}`, {
          headers: {
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${localStorage.access_token}`,
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': localStorage.csrf_token
          }
        })
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
        const tgMessage = `AGENCY DELETED%0A${agencyToDelete.title}%0A${agencyToDelete.field_agency_address}%0A${agencyToDelete.field_email}`
        dispatch('tg', tgMessage, { root: true })
        dispatch('getAgencies')
        return res.status
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    },
    async uploadAgencyImage ({ commit, dispatch, getters }, { id, file }) {
      try {
        const res = await axios.post(`${getters.api}/node/agency/${id}/field_logo`, file, {
          headers: {
            'X-CSRF-Token': localStorage.csrf_token,
            Authorization: `Bearer ${localStorage.access_token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': 'file; filename="logo1.png"'
          }
        })
        if (res.status === 200 || res.status === 201) {
          dispatch('getAgency', id)
        }
        commit('setNotification', { visible: true, type: 'success', content: res.statusText }, { root: true })
      } catch (error) {
        if (error.response.data?.errors?.length > 0) {
          const errors = error.response.data.errors.map(e => `<p><b>${e.title}</b><br>(${e.detail})</p>`).join('')
          commit('setNotification', { visible: true, type: 'error', content: errors }, { root: true })
        } else {
          commit('setNotification', { visible: true, type: 'error', content: error.response.data.message }, { root: true })
        }
      }
    }
  }
}
